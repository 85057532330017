.badge-sm,
.badge-md,
.badge-lg {
  gap: 10px;
  width: fit-content;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  margin-top: 5px;
  border-radius: 7px;
  padding: 4px 8px;
  font-family: rainyhearts;
}

.badge-sm {
  font-size: 12px;
}

.badge-md {
  font-size: 14px;
}

.badge-lg {
  font-size: 16px;
}

.icon {
  font-size: 20px;
}