.section {
  flex-direction: column;
  gap: 10px;
  padding: 20px 10px;
  align-items: center;
  justify-content: center;
  width: 465px;
  height: 145px;
}

.title {
  font-size: inherit;
  font-weight: inherit;
}

.description {
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-left: .5rem;
  color: rgb(243 244 246 / 0.7);
}

.private {
  margin-left: .5rem;
  border: max(1px, 0.0625rem) solid #30363d;
  border-radius: 2em;
  font-size: .75rem;
  font-weight: 500;
  line-height: 18px;
  padding: 0 7px;
  white-space: nowrap;
}

@media screen and (max-width: 670px) {
  .section {
    width: 95%;
    height: 145px;
  }
}