/* 变量 */
:root {
  --body-background-color: #000000;
  --main-text-color: #ffffff;
  --main-gradient: linear-gradient(90deg,#e7548c 0%,#eb70e7 25%,#7580e6 50%,#07b9f3 75%,#02d3ec);
}

/* 全局样式 */
* {
  margin: 0;
  padding: 0;
  user-select: none;
}

*, *::before, *::after {
  border-width: 0;
  border-style: solid;
  box-sizing: border-box;
  border-color: var(--chakra-colors-chakra-border-color);
  word-wrap: break-word;
}

::-webkit-scrollbar {
  display: none;
}

html, body {
  color: var(--main-text-color);
  width: 100%;
  height: 100%;
  background-color: var(--body-background-color);
  overflow-x: hidden;
  font-family: "HarmonyOS_Regular", sans-serif;
}

#app {
  width: 100vw;
  height: 100vh;
}