.avatar {
  width: 10vw;
}
.time {
    margin-right: 15px
}

@media screen and (max-width: 1000px) {
    .avatar {
        width: 15vw;
    }
    .siminfo {
        flex-direction: column;
    }
    .time {
        width: 47.5%;
        margin-right: 5%;
    }
    .tags {
        width: 47.5%;
    }
    .hitokoto {
        margin-left: 0;
    }
    .infos {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .avatar {
        width: 20vw;
    }
}

@media screen and (max-width: 500px) {
    .avatar {
        width: 30vw;
    }
}

@media screen and (max-width: 400px) {
    .avatar {
        width: 40vw;
    }
}

@media screen and (max-width: 300px) {
    .avatar {
        width: 50vw;
    }
}

@media screen and (max-width: 200px) {
    .avatar {
        width: 60vw;
    }
}

@media screen and (max-width: 100px) {
    .avatar {
        width: 70vw;
    }
}